<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Thống kê quà còn lại</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-6">

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Chọn campaign:</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.event_id"
                      >
                        <option value="">--Chọn--</option>
                        <option v-for="(item, index) in events" :value="item.id" :key="index">{{ item.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-2 ">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>

                    </div>

                  </div>

                </b-form>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >
                <template #cell(img)="data">
                 <img v-if="data.item.combo" width="50" :src="data.item.combo.img" />
                </template>

                <template #cell(combo_name)="data">
                  <span v-if="data.item.combo">{{ data.item.combo.title }}</span>
                </template>

                <template #cell(total_gift)="data">
                  <span>{{ data.item.quantity }}</span>
                </template>

                <template #cell(total_release)="data">
                  <span>{{ data.item.total_releases_count }}</span>
                </template>

                <template #cell(total_remain)="data">
                  <span>{{ data.item.quantity - data.item.total_releases_count }}</span>
                </template>

              </b-table>

            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        event_id: "",
      },
      fieldsLog: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "img",
          label: "Hình ảnh",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "combo_name",
          label: "Tên combo",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_gift",
          label: "Tổng quà",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "total_release",
          label: "Đã phát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "total_remain",
          label: "Còn lại",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

      ],
      events: [],
      items: [],
    };
  },
  created() {
    this.loadEvent();
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê quà còn lại", route: "/combo-voucher/report/event-voucher-release" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletCampaignRepository.reportEventVoucherRelease(this.paramFilter);
        this.$bus.$emit("show-loading", false);

        this.items = response.data.data[0].combo_voucher_items;

      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    async loadEvent() {
      try {
        let response = await WalletCampaignRepository.getEvents({
          is_show_all: true
        });
        this.events = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>

<style>
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
</style>
